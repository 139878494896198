import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import {
  CONTACTGet,
  SetCONTACTObj,
  CONTACTDelete,
} from "../../../redux/actions/Contact/Contact.actions";
import { generateFilePath } from "../../Utility/utils";
import { toastError } from "../../Utility/ToastUtils";
import { downloadContact } from "../../../services/contact.service";

function Contact() {
  const dispatch = useDispatch();

  const bannerArr = useSelector((state) => state.contact.contacts);

  useEffect(() => {
    dispatch(CONTACTGet("contactType=''"));
  }, []);

  const handleEdit = (row) => {
    console.log(row, "row row row");
    dispatch(SetCONTACTObj(row));
  };

  const handleBlogDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(CONTACTDelete(row));
    }
  };

  const handleDownload = async () => {
    try {
      let query = "";
      let url = await downloadContact(query);

      window.open(url);
      return;
    } catch (error) {
      console.error(error);
      toastError(error);
    }
  };
  const brand_columns = [
    {
      name: "Date",
      selector: (row, index) => new Date(row.createdAt).toDateString(),
      sortable: true,
      width: "20%",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "10%",
    },
    {
      name: "Email",
      grow: 0,
      selector: (row) => row.email,
      width: "10%",
    },
    {
      name: "Phone",
      button: true,
      selector: (row) => row.phone,
      width: "20%",
    },
    {
      name: "Message",
      button: true,
      selector: (row) => row.message,
      width: "20%",
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          onDeleteClick={() => handleBlogDelete(row?._id)}
          deletePath="/Contact"
          remove
          Uniquekey={row.id}
        />
      ),
    },
  ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="main_text_color m-0">Contact List</h5>
                <div className="d-flex align-items-center gap-3">
                  <button
                    className="btn btn-1 bg-black text-white rounded-pill btn-sm"
                    type="button"
                    onClick={() => handleDownload()}
                  >
                    <i className="fa fa-download"></i> Download
                  </button>
                  {/* <SearchBox extraClass="bg-white" /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={brand_columns}
                  data={bannerArr && bannerArr.length > 0 ? bannerArr : []}
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Contact;
