import React from "react";
import { images } from "../Images/Images";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { rolesObj } from "../../utils/roles";

function SideBar({ style }) {
  let role = useSelector((state) => state.auth.role);
  let permisionArr = useSelector((state) => state.auth.permissions);

  let location = useLocation();
  const [sidebar_item, setsidebar_item] = useState([
    {
      isrotated: true,
      active: true,
      name: "Dashboard",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Dashboard",
      icon: "ion-grid",
      children: [],
    },

    {
      isrotated: false,
      active: false,
      name: "Products",

      icon: "fa-brands fa-product-hunt",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      children: [
        {
          name: "Category",
          roleArr: ["ADMIN", "SUBADMIN"],
          path: "/Product/Category",
          active: false,
        },

        {
          name: "Attribute",
          path: "/Product/Attribute",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },

        {
          name: "Products",
          path: "/Product/Product-List",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Product Review",
          path: "/Review/Product-Review",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },

    {
      isrotated: true,
      active: true,
      name: "Artist",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Artist/Artist",
      icon: "ion-grid",
      children: [],
    },

    {
      isrotated: true,
      active: true,
      name: "Collection",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Collection/Collection",
      icon: "ion-grid",
      children: [],
    },

    {
      isrotated: true,
      active: true,
      name: "Location",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Location/Location",
      icon: "ion-grid",
      children: [],
    },

    {
      isrotated: true,
      active: true,
      name: "Gallery",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      path: "/Gallery",
      icon: "ion-grid",
      children: [],
    },
    {
      isrotated: false,
      active: false,
      name: "Order Manage",

      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
      icon: "ion-android-cart",
      children: [
        {
          name: "Total Order",
          path: "/Order/Total-Order",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Coupon",
    //   roleArr: ["ADMIN", "SELLER", "SUBADMIN"],

    //   icon: "fa-solid fa-gift",
    //   children: [
    //     {
    //       name: "Coupon",
    //       path: "/Coupon/Coupon",
    //       roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
    //       active: false,
    //     },

    //   ],
    // },
    {
      isrotated: false,
      active: false,
      name: "Users",

      icon: "fa-solid fa-users",
      roleArr: ["ADMIN", "SUBADMIN"],
      children: [
        {
          name: "All Users",
          path: "/User-list",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Customer KYC",
          path: "/Customer/KYC",
          active: false,
        },
      ],
    },

    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Pages",

    //   icon: "fa-solid fa-users",
    //   roleArr: ["ADMIN", "SUBADMIN", "SELLER"],
    //   children: [
    //     {
    //       name: "View Page",
    //       path: "Page/page",
    //       roleArr: ["ADMIN", "SUBADMIN", "SELLER"],
    //       active: false,
    //     },
    //   ],
    // },

    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Career",

    //   icon: "fa-solid fa-users",
    //   roleArr: ["ADMIN", "SUBADMIN", "SELLER"],
    //   children: [
    //     {
    //       name: "Career",
    //       path: "Career/career",
    //       roleArr: ["ADMIN", "SUBADMIN", "SELLER"],
    //       active: false,
    //     },

    //     {
    //       name: "Career Enquiry",
    //       path: "Career/career/Apply",
    //       roleArr: ["ADMIN", "SUBADMIN", "SELLER"],
    //       active: false,
    //     },
    //   ],
    // },
    // {
    //   isrotated: false,
    //   active: false,
    //   name: "Stocks",

    //   icon: "fa-solid fa-box",
    //   roleArr: ["SELLER", "ADMIN"],
    //   children: [
    //     {
    //       name: "My Stocks",
    //       path: "/my-stocks",
    //       roleArr: ["SELLER", "ADMIN", "SUBADMIN"],
    //       active: false,
    //     },

    //   ],
    // },

    {
      isrotated: false,
      active: false,
      name: "Frontend CMS",

      roleArr: ["ADMIN", "SUBADMIN"],
      icon: "fa-solid fa-user",
      children: [
        {
          name: "Banners",
          path: "/Banners",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Homepage",
          path: "/HomepageCMS",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Faq",
          path: "/Faq",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Privacy-Policy",
          path: "/Privacy-Policy",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Refund-Policy",
          path: "/Refund-Policy",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        {
          name: "Terms-Conditions",
          path: "/Terms-Conditions",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
        // {
        //   name: "AboutPage",
        //   path: "/AboutPage",
        //   roleArr: ["ADMIN", "SUBADMIN"],
        //   active: false,
        // },
        // {
        //   name: "BlogPage",
        //   path: "/BlogPage",
        //   roleArr: ["ADMIN", "SUBADMIN"],
        //   active: false,
        // },
        // {
        //   name: "Filter",
        //   path: "/Filter",
        //   roleArr: ["ADMIN", "SUBADMIN"],
        //   active: false,
        // },

        {
          active: false,
          name: "Seo",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          path: "/Seo/View-Seo",
          icon: "fa fa-list",
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Blog",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],

      icon: "fa-solid fa-users",
      children: [
        {
          name: "Blog",
          path: "/Blog/post",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Blog category",
          path: "/Blog/Category",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Wallet Manage",

      icon: "fa-solid fa-wallet",
      children: [
        {
          name: "Wallet Recharge",
          path: "/Wallet/Recharge",
          active: false,
        },
        {
          name: "Configuration",
          path: "/Wallet/Configuration",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Marketing",

      icon: "fa-solid fa-user",
      children: [
        {
          name: "News Letters",
          path: "/Marketing/NewsLetter",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Review",
      roleArr: ["SELLER", "SUBADMIN"],

      icon: "fa-solid fa-user",
      children: [
        {
          roleArr: ["SELLER", "SUBADMIN"],
          name: "Product Review",
          path: "/Review/Product-Review",
          active: false,
        },
        {
          roleArr: ["SELLER", "SUBADMIN"],
          name: "Company Review ",
          path: "/Review/Seller-Review",
          active: false,
        },
        {
          roleArr: ["SELLER", "SUBADMIN"],
          name: "Review Configuration",
          path: "/Review/Review-Configuration",
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Query",
      roleArr: ["ADMIN", "SELLER", "SUBADMIN"],

      icon: "fa-solid fa-cog",
      children: [
        {
          name: "Contact",
          path: "/Contact",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },

        {
          name: "ProductEnquiry",
          path: "/ProductEnquiry",
          roleArr: ["ADMIN", "SUBADMIN"],
          active: false,
        },
      ],
    },
    {
      isrotated: false,
      active: false,
      name: "Settings",
      roleArr: ["SELLER", "SUBADMIN", "ADMIN"],

      icon: "fa-solid fa-user",
      children: [
        {
          name: "Site Setting",
          path: "/System-Setting/Setting",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
        {
          name: "Settings",
          path: "/System-Setting/EmailSettings",
          roleArr: ["ADMIN", "SELLER", "SUBADMIN"],
          active: false,
        },
      ],
    },

    {
      isrotated: false,
      active: false,
      name: "Contact Request",

      icon: "fa-solid fa-user",
      children: [
        {
          name: "Contact Mail",
          path: "/Contact-Mail",
          active: false,
        },
      ],
    },
  ]);

  const isRotating = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (index === i) {
        el.isrotated = !el.isrotated;
        el.active = true;
      } else {
        el.active = false;
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const childActive = (i) => {
    let temp_array = sidebar_item.map((el, index) => {
      if (el.children && el.children.length > 0) {
        el.children.map((item, childIndex) => {
          if (childIndex === i) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
      }
      return el;
    });
    setsidebar_item([...temp_array]);
  };

  const handleCheckIfElementIsAllowed = (element) => {
    console.log(element, "element");
    let tempValue = false

    if(role == rolesObj.ADMIN){
      return true
    }

    if(element.children && element.children.length > 0){
        tempValue = element.children.some((el) => permisionArr.some(
          (ele) => (ele.url.trim().toLowerCase() == el.path.trim().toLowerCase() && (ele.permissionsObj.CREATE || ele.permissionsObj.READ ))
        ))
      }
      else{
        console.log(element, "clfg")
        if(element.path){
          tempValue = permisionArr.some((ele) => ele.url.trim().toLowerCase() == element.path.trim().toLowerCase() && (ele.permissionsObj.CREATE || ele.permissionsObj.READ))
        }
      }
      if(tempValue){
        console.log(tempValue,"tempValue", element,role)
      }
      return tempValue
  };


  const handleCheckIfChildElementIsAllowed = (element) => {
    console.log(element, "element");
    let tempValue = false

    if(role == rolesObj.ADMIN){
      return true
    }

    if(element.children && element.children.length > 0){
        tempValue = element.children.some((el) => permisionArr.some(
          (ele) => (ele.url.trim().toLowerCase() == el.path.trim().toLowerCase() && (ele.permissionsObj.CREATE || ele.permissionsObj.READ ))
        ))
      }
      else{
        console.log(element, "clfg")
        if(element.path){
          tempValue = permisionArr.some((ele) => ele.url.trim().toLowerCase() == element.path.trim().toLowerCase() && (ele.permissionsObj.CREATE || ele.permissionsObj.READ))
        }
      }
      if(tempValue){
        console.log(tempValue,"tempValue", element,"role")
      }
      return tempValue
  };

 
  return (
    <div id="sidebar" className="displayNone">
      <div
        className="main-logo"
        style={style ? { padding: "26px 10px", height: "auto" } : {}}
      >
        {style ? (
          <img src={images?.favicon} alt="" />
        ) : (
          <img style={{ height: 70 }} src={images?.footerlogo} alt="" />
        )}
      </div>
      <ul className="sidebar-menu" id="sidebarMenu">
        {sidebar_item &&
          sidebar_item?.filter(el=> handleCheckIfElementIsAllowed(el))?.map((item, i) => {
            if (
              
              typeof array === "undefined" &&
              item?.children &&
              item?.children?.length === 0 &&
              item?.roleArr.some(
                (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
              )
            ) {
              return (
                <li key={`sidebar_item_${i}`}>
                  <Link
                    to={item?.path}
                    className={item?.active ? "active" : ""}
                    onClick={() => isRotating(i)}
                  >
                    <i className={item?.icon}></i>
                    {!style && <p className="mb-0">{item?.name}</p>}
                  </Link>
                </li>
              );
            } else {
              if (
                item?.roleArr &&
                item?.roleArr?.length > 0 &&
                item?.roleArr.some(
                  (el) => `${el}`.toLowerCase() == `${role}`.toLowerCase()
                )
              ) {
                return (
                  <li key={`sidebar_item_${i}`}>
                    <Link
                      to={`#sidebar_item_children_${i}`}
                      className={
                        item?.active || location === item?.path ? "active" : ""
                      }
                      data-bs-toggle="collapse"
                      aria-expanded={item?.active}
                      aria-controls={`sidebar_item_children_${i}`}
                      role="button"
                      onClick={() => isRotating(i)}
                    >
                      <i className={item.icon}></i>
                      {!style && (
                        <p className="mb-0">
                          {item?.name}
                          {item?.isrotated ? (
                            <i className="ion-arrow-up-b pe-3"></i>
                          ) : (
                            <i className="ion-arrow-down-b pe-3"></i>
                          )}
                        </p>
                      )}
                    </Link>
                    {!style && (
                      <ul
                        className="collapse"
                        id={`sidebar_item_children_${i}`}
                        data-bs-parent="#sidebarMenu"
                      >
                        {item?.children?.filter(el=> handleCheckIfElementIsAllowed(el))?.map((child, index) => {
                          if (
                            child?.roleArr &&
                            child?.roleArr?.length > 0 &&
                            child?.roleArr?.some(
                              (el) =>
                                `${el}`?.toLowerCase() ==
                                `${role}`?.toLowerCase()
                            )
                          ) {
                            return (
                              <li key={`${child?.name}_${index}`}>
                                <Link
                                  to={child?.path}
                                  className={
                                    child?.active || location === child?.path
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => childActive(index)}
                                >
                                  {child?.name}
                                </Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    )}
                  </li>
                );
              }
            }
          })}
      </ul>
    </div>
  );
}

export default SideBar;
