import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { PRODUCTUpdate } from "../../../redux/actions/Product/Product.actions";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal, ImageSelectModal } from "../../Utility/Modal";
import { toastError } from "../../Utility/ToastUtils";
import RelatedProduct from "./RelatedProduct";
import { DISCOUNT_TYPE } from "../../Utility/constants";
import MultiFileUpload from "../../Utility/MultipleFileUpload";
import { generateFilePath } from "../../Utility/utils";
import { COLLECTIONGet } from "../../../redux/actions/Collection/Collection.actions";
import { ARTISTGet } from "../../../redux/actions/Artist/Artist.actions";
import { LOCATIONGet } from "../../../redux/actions/Location/Location.actions";
function UpdateGeneralProduct() {
  const dispatch = useDispatch();
  const collectionArrFromRedux = useSelector(
    (state) => state.collection.collections
  );
  const artistArrFromRedux = useSelector(
    (state) => state.artist.artists
  );
  const locationtArrFromRedux = useSelector(
    (state) => state.location.locations
  );
  const [mainCollectionArr, setMainCollectionArr] = useState([]);
  const [mainArtistArr, setMainArtistArr] = useState([]);
  const [mainLocationArr, setMainLocationArr] = useState([]);
  const [mediaModal, setMediaModal] = useState(false);
  const [mediaVariotionModal, setMediaVariotionModal] = useState(false);
  const [mediaMainModal, setMediaMainModal] = useState(false);
  const [mediaModalCurrentIndex, setMediaModalCurrentIndex] = useState(0);

  const [collection, setCollection] = useState({value:"",label:""});
  const [artist, setArtist] = useState({value:"",label:""});
  const [location, setLocation] = useState({value:"",label:""});
  const [internalCode, setInternalCode] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [mainImageAlt, setMainImageAlt] = useState("");
  const [backImage, setBackImage] = useState("");
  const [backImageAlt, setBackImageAlt] = useState("");

  //////product details
  const [name, setName] = useState("");
  const [vendorSku, setVendorSku] = useState("");
  const [despcription, setDespcription] = useState("");
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [stock, setStock] = useState(0);

  const [discountType, setDiscountType] = useState({
    label: DISCOUNT_TYPE.NONE,
    value: ""
  });

  const [productDisplayType, setproductDisplayType] = useState([
    { label: "None", value: "" },
    { label: "Hot Deals", value: "hot_deals" },
    { label: "Featured Product", value: "featured_product" },
    { label: "New Arrivals", value: "new_arrival" },
    { label: "BestSeller", value: "bestseller" },
  ]);

  const [productDisplay, setproductDisplay] = useState({
    label: "None",
    value: ""
  });
  const [genderType, setgenderType] = useState([
    { label: "None", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Kids", value: "kids" },
  ]);
  const [gender, setgender] = useState({
    label: "None",
    value: ""
  });

  const [isSale, setisSale] = useState(false);
  const [isDiscount, setisDiscount] = useState(false);
  const [isNew, setisNew] = useState(false);
  const [isPremium, setisPremium] = useState(false);
  const [short_description, setShortDescription] = useState("");
  const [long_description, setLongDescription] = useState("");
  const [edition, setEdition] = useState("");
  const [instructions, setInstructions] = useState("");
  const [imageArr, setImageArr] = useState([]);
  //////size of product
  const [product_dimension_width, setProduct_Dimension_width] = useState(0);
  const [product_dimension_height, setProduct_Dimension_height] = useState(0);
  const [product_dimension_length, setProduct_Dimension_length] = useState(0);
  const [product_dimension_weight, setProduct_Dimension_weight] = useState(0);
  const [mrp, setMrp] = useState(0);


  ///////tags
  const [tags, setTags] = useState("");
  //////brand
  const [selectedBrandId, setSelectedBrandId] = useState("");
  //////category
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  ///////////////////////////////////////////////////////////////
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  ///////////////////////////////////////////////////////////////
  const [videoLink, setVideoLink] = useState("");
  const [productImageStr, setProductImageStr] = useState("");
  const [specificationFile, setSpecificationFile] = useState("");
  const [displayAttributesArr, setDisplayAttributesArr] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [status, setStatus] = useState("Publish");
  const [productAvailability, setProductAvailability] = useState("");
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [mediaArr, setMediaArr] = useState([]);
  const [tabList, settabList] = useState([
    {
      tabName: "RELATED PRODUCT",
      active: false,
      render: <RelatedProduct name="Related Product" />,
    },
  ]);

  useEffect(() => {
    if (collectionArrFromRedux) {
      setMainCollectionArr([...collectionArrFromRedux]);
    }
  }, [collectionArrFromRedux]);
  useEffect(() => {
    if (artistArrFromRedux) {
      setMainArtistArr([...artistArrFromRedux]);
    }
  }, [artistArrFromRedux]);
  useEffect(() => {
    if (locationtArrFromRedux) {
      setMainLocationArr([...locationtArrFromRedux]);
    }
  }, [locationtArrFromRedux]);
  ///////////////////////////////////////////////////////////////
  const authUser = useSelector((state) => state.auth.user);
  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  const productObj = useSelector((state) => state.product.productObj);
  const attributes = useSelector((state) => state.attribute.attributes);
  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map((el) => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some((el) => el != el._id)) {
            tempArr.push(el._id);
          }
          setSelectedCategoryArr([...tempArr]);
          return { ...el, categoryId: el._id, subCategoryArr: handleFilterChecked(el.subCategoryArr) };
        } else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some((el) => el != el._id)) {
              tempArr.push(el._id);
            }
            setSelectedCategoryArr([...tempArr]);
            return { ...el, categoryId: el._id };
          }
        }
      });
    } else {
      return arr;
    }
  };

  const handleSubmit = () => {
    let cat_arr = returnSelectedCategories(mainCategoryArr);

    let tempCategoryArr = handleFilterChecked(mainCategoryArr);
    // let tempCategoryArr = handleFilterChecked(mainCategoryArr)
    console.log(selectedCategoryArr, "selectedCategoryArr");
    // console.log(selectedCategoryArr, "selectedCategoryArr")
    let obj = {
      categoryArr: cat_arr.map((el) => {
        return { categoryId: el._id };
      }),
      collectionId:collection?.value,
      artistId:artist?.value,
      locationId:location?.value,
      productCode: internalCode,
      name,
      createdBy: authUser?.user?._id,
      sku: vendorSku,
      short_description,
      long_description,
      edition,
      instructions,
      price,
      mrp,
      stock,
      isSale,
      isNew,
      mainImage,
      imageArr: imageArr,
      product_dimension_width,
      product_dimension_height,
      product_dimension_length,
      product_dimension_weight,
      tags,
      discount,
      isPremium,
      backImage,
      backImageAlt,
      mainImageAlt,
      discountType: discountType.value,
      attributesArr: displayAttributesArr,
      videoLink: videoLink ? videoLink : "",
      status,
      metaTitle,
      metaDescription,
      metaImage,
      productDisplay: productDisplay.value,
      gender: gender.value
    };
    console.log(obj, "send Obj");
    dispatch(PRODUCTUpdate(obj, productObj._id));
  };
  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(BrandGet());
    dispatch(COLLECTIONGet("level=1"));
    dispatch(ARTISTGet("level=1"));
    dispatch(LOCATIONGet("level=1"));
    // handleGet();
    dispatch(ATTRIBUTEGet());
  }, []);
  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);
  useEffect(() => {
    if (attributes && attributes.length > 0) {
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);
  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    let subCategories = arr.reduce((acc, el) => [...acc, ...el.subCategoryArr.filter((el) => el.checked)], []);
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)];
    } else {
      return [...new_selected_arr];
    }
  };

  // const handleBrandSelection = (obj) => {
  //   setSelectedBrandId(obj?._id);
  //   setBrandObj(obj);
  // };
  const handleFileSet = (value, index) => {
    if (value && value.length > 0) {
      let tempArr = imageArr;
      let tempImageArr = value.map((img) => ({ image: img.base64 }))
      const children = tempArr.concat(tempImageArr);
      setImageArr([...children]);
    } else {
      let tempArr = imageArr;
      tempArr[index].image = value;
      setImageArr([...tempArr]);
    }
  };
  const handleproductImageAltEntry = (value, index) => {
    let tempArr = imageArr;
    tempArr[index].imageAlt = value;
    setImageArr([...tempArr]);
  };
  const handlePdfFileSet = (value) => {
    setSpecificationFile(value);
  };
  const handleAttributeVariantChange = (index) => {
    let tempArr = [...mainAttributesArr];
    tempArr[index].checked = !tempArr[index].checked;
    setMainAttributesArr(tempArr);
  };
  const handleInnerAttributeVariantChange = (index, innerIndex) => {
    let tempArr = [...mainAttributesArr];
    tempArr[index].attributeValueArr[innerIndex].checked = !tempArr[index].attributeValueArr[innerIndex].checked;
    setMainAttributesArr([...tempArr]);
    generateCombinations(tempArr);
  };
  ////////////p n c generator
  const generateCombinations = (tempattributeArr) => {
    console.log(tempattributeArr, "tempattributeArr");
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // base64/stackoverflow.com/a/57015870
      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeValueArr: [...el.attributeValueArr],
        }));
      }
      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: 0,
          }))
        );
      }, []);
      return combine([combined, ...tailTail]);
    };
    let finalArr = [];
    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked);
        console.log(tempArr, "tempArr");
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => ({
              name: el.name,
              attributeValueArr: [
                {
                  attributeId: el._id,
                },
              ],
              price: 0,
              imagesArr: ''
            }))
          );
        }
      }
    });
    console.log(finalArr, "finalArr");
    if (finalArr.length) {
      finalArr = combine(finalArr);
    } else {
      setDisplayAttributesArr([]);
    }

    // console.log(finalArr, 'FINAL ARR')
    // finalArr
    if (finalArr.length) {
      setDisplayAttributesArr([
        ...finalArr.map((el) => ({
          ...el,
          price: 0,
          discount: 0,
          currentStock: 0,
          stock: 0,
          imagesArr: []
        })),
      ]);
    }
  };
  const handleDisplayAttributesArrChange = (e, index, key) => {
    let tempArr = [...displayAttributesArr];
    tempArr[index][key] = e;
    setDisplayAttributesArr([...tempArr]);
  };

  useEffect(() => {
    if (productObj && productObj?._id) {
      console.log(productObj, "productObj");
      setName(productObj?.name);
      setInternalCode(productObj?.productCode);
      setVendorSku(productObj?.sku);
      setPrice(productObj?.price);
      setMrp(productObj?.mrp);
 
      setStock(productObj.stock);
      setProduct_Dimension_width(productObj?.product_dimension_width);
      setProduct_Dimension_height(productObj?.product_dimension_height);
      setProduct_Dimension_length(productObj?.product_dimension_length);
      setProduct_Dimension_weight(productObj?.product_dimension_weight);
      setTags(productObj?.tags);
      setMetaTitle(productObj?.metaTitle);
      setMetaDescription(productObj?.metaDescription);
      setMetaImage(productObj?.metaImage);
      setImageArr(productObj?.imageArr);
      setVideoLink(productObj?.videoLink);
      setStatus(productObj?.status);

      setShortDescription(productObj?.short_description ? productObj?.short_description :"");
      setLongDescription(productObj?.long_description ? productObj?.long_description :"");
      setEdition(productObj?.edition ? productObj?.edition :"");
      setInstructions(productObj?.instructions ? productObj?.instructions :"");
      // setDiscount(productObj?.discount);
      // setisNew(productObj?.isNew);
      // setisPremium(productObj?.isPremium);
      // if(productObj?.isSale){
      //  setisSale(productObj?.isSale);
      // }
      setMainImage(productObj.mainImage);
      setMainImageAlt(productObj.mainImageAlt);
     
      if(productObj?.productDisplay){
        let producttypeObj = productDisplayType.find((el) => el.value == productObj?.productDisplay);
        if(producttypeObj?.value){
          setproductDisplay(producttypeObj)
        }
      }
   

    
    
    }
  }, [productObj]);

  // useEffect(() => {
  //   if (brands && productObj) {
  //     // console.log(brands, "brands")
  //     // console.log(productObj, "productObj")
  //     setSelectedBrandId(productObj?.brandId);
  //     // console.log(brands.find(el => el._id == productObj.brandId), "brands.find(el => el._id == productObj.brandId)")
  //     let tempBrandObj = brands.find((el) => el._id == productObj?.brandId);
  //     if (tempBrandObj) {
  //       tempBrandObj.label = tempBrandObj?.name;
  //       tempBrandObj.value = tempBrandObj?._id;
  //       setBrandObj(tempBrandObj);
  //     }
  //   }
  // }, [productObj, brands]);

  useEffect(() => {
    if (attributes && attributes.length > 0 && productObj && productObj.attributesArr && productObj.attributesArr.length > 0) {
      let temp = handleAttributesArrSelectionOnInit(attributes, productObj?.attributesArr);
      // setDisplayAttributesArr([...temp])
      console.log(attributes, "ATTRIBUTESSSS");
      setDisplayAttributesArr(productObj.attributesArr);
      console.log(productObj.attributesArr, "productObj.attributesArr");
    }
  }, [productObj, attributes]);
  const handleAttributesArrSelectionOnInit = (existingAttributesArr, attributesArrFromDb) => {
    for (const el of existingAttributesArr) {
      if (el.attributeValueArr && el.attributeValueArr.length > 0) {
        for (const ele of el.attributeValueArr) {
          if (attributesArrFromDb?.some((elx) => elx?.attributeValueArr?.some((elm) => elm.attributeId == ele._id))) {
            ele.checked = true;
            el.checked = true;
          }
        }
      }
    }

    return existingAttributesArr;
  };

  const handleCategorySelectOnInit = (selectedCategoryArrFromDB, categoryArr) => {
    let tempArr = categoryArr.map((el) => {
      if (selectedCategoryArrFromDB.some((ele) => ele.categoryId == el._id)) {
        el.checked = true;
      }
      if (el.subCategoryArr) {
        handleCategorySelectOnInit(selectedCategoryArrFromDB, el.subCategoryArr);
      }
      return el;
    });
  };
  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
    
  }, [categoryArr, productObj]);

  useEffect(() => {
    if (productObj && mainArtistArr) {
      if(productObj?.artistId){

        let producttypeObj = mainArtistArr.find((el) => `${el?._id}` == `${productObj?.artistId}`);
        if(producttypeObj?._id){
          setArtist({label:producttypeObj?.name,value:producttypeObj?._id})
        }
      }
    }
  }, [mainArtistArr, productObj]);


  useEffect(() => {
    if (productObj && mainCollectionArr) {
      if(productObj?.collectionId){
        let producttypeObj = mainCollectionArr.find((el) => el?._id == productObj?.collectionId);
        if(producttypeObj?._id){
          setCollection({label:producttypeObj?.name,value:producttypeObj?._id})
        }
      }
    }
  }, [mainCollectionArr, productObj]);


  useEffect(() => {
    if (productObj && mainLocationArr) {
      if(productObj?.locationId){
        let producttypeObj = mainLocationArr.find((el) => el?._id == productObj?.locationId);
        if(producttypeObj?._id){
          setLocation({label:producttypeObj?.name,value:producttypeObj?._id})
        }
      }
    }
  }, [mainLocationArr, productObj]);

  // const handleDiscountTypeChange = (obj) => {
  //   setSelectedDiscountType(obj.value);
  // };

  const options = [
    { value: "chocolate", label: "CGST" },
    { value: "strawberry", label: "IGST" },
    { value: "vanilla", label: "SGST" },
  ];



  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };

  const handleDisplayAttributesArrImageChange = (value, index) => {


    console.log(value.length, "valuevaluevaluevalue")



    let tempArr = [...displayAttributesArr];
    if (value && value.length > 0) {

      let temoImageArr = tempArr[index]["imagesArr"];
      let tempImageArr = value.map((img) => ({ image: img.base64, name: "" }))

      console.log(tempImageArr, "multiple",temoImageArr)
      if (tempImageArr && tempImageArr?.length > 0) {
        const children = temoImageArr.concat(tempImageArr);
      
        tempArr[index]["imagesArr"] = children
        setDisplayAttributesArr([...tempArr]);
      }

    }
    else {
      let temoImageArr = tempArr[index]["imagesArr"];
      console.log(temoImageArr, "single")

      temoImageArr.push({
        image: value,
        name: ""
      })

      tempArr[index]["imagesArr"] = temoImageArr
      setDisplayAttributesArr([...tempArr]);
    }


  }


  const handleMultipleImagesRemove = (atrIndex, index) => {
    let tempArr = [...displayAttributesArr];
    let temoImageArr = tempArr[atrIndex]["imagesArr"];
    temoImageArr = temoImageArr.filter((el, indexxxx) => indexxxx != index)
    console.log(temoImageArr, "temoImageArr")

    tempArr[atrIndex]["imagesArr"] = [...temoImageArr]
    console.log(tempArr, "tempArr")

    setDisplayAttributesArr([...tempArr]);
  };

  const handleImageObjAdd = () => {
    let tempArr = imageArr;
    tempArr.push({ image: "", imageAlt: "" });
    setImageArr([...tempArr]);
  };
  const handleImageObjRemove = (indexe) => {
    let tempArr = imageArr.filter((el, index) => index != indexe);
    setImageArr([...tempArr]);
  };

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(el.subCategoryArr, id, value);
          return el;
        } else {
          return el;
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr]);
  };

  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div key={obj._id} className="col-12 mb-3" style={{ marginLeft: `${obj.level + 5}px` }}>
        <input className="form-check-input pointer" checked={obj.checked} value={tags} onChange={(event) => handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)} type="checkbox" />
        <label style={{ paddingLeft: 5 }}>{obj.name}</label>
        {obj.checked &&
          obj.subCategoryArr &&
          obj.subCategoryArr.length > 0 &&
          obj.subCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
      </div>
    );
  };

  return (
    <form className="form">
      <div className="row">
        <div className="col-12 col-md-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-4">Product Information</h5>

              <div className="col-12 col-md-12 mb-3">
                <label>
                  Product Name <span className="red">*</span>
                </label>
                <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Product Code <span className="red">*</span></label>
                <input value={internalCode} onChange={(event) => setInternalCode(event.target.value)} type="text" className="form-control" />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  PRODUCT SKU <span className="red">*</span>
                </label>
                <input value={vendorSku} onChange={(event) => setVendorSku(event.target.value)} type="text" className="form-control" />
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Price<span className="red">*</span>
                </label>
                <input value={price} onChange={(event) => setPrice(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Stock  <span className="red">*</span>
                </label>
                <input value={stock} onChange={(event) => setStock(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Mrp  <span className="red">*</span>
                </label>
                <input value={mrp} onChange={(event) => setMrp(event.target.value)} type="text" className="form-control" />
              </div>
             
              
             

              <div className="col-12 col-md-6 mb-3">
                <label>
                  Product Display  <span className="red">*</span>
                </label>
                <Select value={productDisplay} options={productDisplayType} onChange={(val) => setproductDisplay(val)} />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Collection  <span className="red">*</span>
                </label>
                <Select value={collection}     options={
                            mainCollectionArr &&
                            mainCollectionArr.length > 0 &&
                            mainCollectionArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          }onChange={(val) => setCollection(val)} />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Artist  <span className="red">*</span>
                </label>
                <Select value={artist}    options={
                            mainArtistArr &&
                            mainArtistArr.length > 0 &&
                            mainArtistArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          } onChange={(val) => setArtist(val)} />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>
                  Location  <span className="red">*</span>
                </label>
                <Select value={location}    options={
                            mainLocationArr &&
                            mainLocationArr.length > 0 &&
                            mainLocationArr.map((el) => ({
                              label: el.name,
                              value: el._id,
                            }))
                          } onChange={(val) => setLocation(val)} />
              </div>

           {/*      <div className="col-12 col-md-6 mb-3">
                <label>Sale</label>
                <div className="row">
                  <div className="col-3">
                    <input id="isSale" value={true} onChange={(event) => setisSale(event.target.value)} type="radio" className="form-check-input pointer" name={"isSale"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="isSale">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="isSale1" value={false} onChange={(event) => setisSale(event.target.value)} name={"isSale"} className="form-check-input pointer" type="radio" />
                    <label style={{ paddingLeft: 10 }} htmlFor="isSale1">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>New</label>
                <div className="row">
                  <div className="col-3">
                    <input id="setisNew" value={true} checked={isNew == true} onChange={(event) => setisNew(true)} type="radio" className="form-check-input pointer" name={"setisNew1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setisNew">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="setisNew1" value={false} checked={isNew == false} onChange={(event) => setisNew(true)} type="radio" className="form-check-input pointer" name={"setisNew1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setisNew1">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6 mb-3">
                <label>Is Premium</label>
                <div className="row">
                  <div className="col-3">
                    <input id="setisPremium" value={true} checked={isPremium == true} onChange={(event) => setisPremium(true)} type="radio" className="form-check-input pointer" name={"setisPremium1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setisPremium">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="setisPremium1" value={false} checked={isPremium == false} onChange={(event) => setisPremium(true)} type="radio" className="form-check-input pointer" name={"setisPremium1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setisPremium1">
                      No
                    </label>
                  </div>
                </div>
              </div>
            <div className="col-12 col-md-6 mb-3">
                <label>Made To Order</label>
                <div className="row">
                  <div className="col-3">
                    <input id="setIsMadeToOrder" value={true} checked={isMadeToOrder == true} onChange={(event) => setIsMadeToOrder(true)} type="radio" className="form-check-input pointer" name={"setIsMadeToOrder1"} />
                    <label style={{ paddingLeft: 10 }} htmlFor="setIsMadeToOrder">
                      Yes
                    </label>
                  </div>
                  <div className="col-3">
                    <input id="setIsMadeToOrder1" value={false} checked={isMadeToOrder == false} onChange={(event) => setIsMadeToOrder(false)} name={"setIsMadeToOrder1"} className="form-check-input pointer" type="radio" />
                    <label style={{ paddingLeft: 10 }} htmlFor="setIsMadeToOrder1">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Ships In (Days)</label>
                <input value={ships_in_days} onChange={(event) => setShips_in_days(event.target.value)} type="number" className="form-control" />
              </div> */}

              <div className="col-12 mb-3">
                <label>Tags (Comma Separated)</label>
                <input value={tags} onChange={(event) => setTags(event.target.value)} type="text" className="form-control" />
              </div>
              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="main_text_color mb-4">Product Category</h5>
                {mainCategoryArr &&
                  mainCategoryArr.length > 0 &&
                  mainCategoryArr.map((el) => {
                    return handleRenderCheckboxCategory(el);
                  })}
              </div>
              <div className="col-12 col-md-3 mb-3">
                <h5 className="main_text_color mb-4">Product Attributes</h5>

                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div key={el._id}>
                        <input checked={el.checked} onChange={(event) => handleAttributeVariantChange(index)} className="form-check-input pointer" type="checkbox" />
                        <label style={{ paddingLeft: 5 }}>{el.name}</label>

                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map((ele, index2) => {
                                return (
                                  <div key={index2} style={{ marginLeft: 20 }}>
                                    <input checked={ele.checked} onChange={(event) => handleInnerAttributeVariantChange(index, index2)} className="form-check-input pointer" type="checkbox" />
                                    <label style={{ paddingLeft: 5 }}>{ele.label}</label>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              {displayAttributesArr && displayAttributesArr.length > 0 && (
                <div className="col-12 mb-0">
                  <h5 className="main_text_color mb-4">Product Variants Info</h5>
                </div>
              )}
              {displayAttributesArr &&
                displayAttributesArr.length > 0 &&
                displayAttributesArr.map((el, index) => {
                  return (
                    <div className="col-12 col-md-12 " key={index}>
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label>
                          {el.name}
                          <span className="red">*</span>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                          <label>
                            Stock<span className="red">*</span>{" "}
                          </label>
                          <input value={el.stock} onChange={(e) => handleDisplayAttributesArrChange(e.target.value, index, "stock")} type="number" className="form-control" />
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                          <label>
                            Variant Price <span className="red">*</span>
                          </label>
                          <input value={el.price} onChange={(e) => handleDisplayAttributesArrChange(e.target.value, index, "price")} type="number" className="form-control" />
                        </div>
                        {/* <div className="col-12 col-md-4 mb-3">
                          <label>
                            Images <span className="red">*</span><br />
                          </label>
                          <MultiFileUpload
                            onFileChange={(val) => handleDisplayAttributesArrImageChange(val, index)} />

                        </div>
                        <div className="row ">
                          {el?.imagesArr &&
                            el?.imagesArr.length > 0 &&
                            el?.imagesArr.map((imgel, indexxxx) => {
                              return (
                                <div className="col-md-1  ml-1 position-relative" key={indexxxx}>


                                  <img
                                    src={imgel.image.includes('base64') ? imgel.image : generateFilePath(imgel.image)}
                                    width="50px"
                                    height="50px"
                                  />

                                  <button type="button" className="btn btn-sm btn-danger btn-absolute " onClick={() => handleMultipleImagesRemove(index, indexxxx)}  >
                                    <i className="fa fa-close"></i>
                                  </button>
                                </div>

                              );
                            })}


                        </div> */}
                      </div>

                    </div>
                  );
                })}


            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <div className="col-12 mb-0">
                <h5 className="main_text_color mb-4">Weight Height Info</h5>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Weight [Gm]</label>
                <input value={product_dimension_weight} onChange={(event) => setProduct_Dimension_weight(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Length [Cm]</label>
                <input value={product_dimension_length} onChange={(event) => setProduct_Dimension_length(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Breadth [Cm]</label>
                <input value={product_dimension_width} onChange={(event) => setProduct_Dimension_width(event.target.value)} type="number" className="form-control" />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Height [Cm]</label>
                <input type="number" value={product_dimension_height} onChange={(event) => setProduct_Dimension_height(event.target.value)} className="form-control" />
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-4">Short Description</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow" value={short_description} onChange={(e) => setShortDescription(e)} />
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-4">Long Description</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow" value={long_description} onChange={(e) => setLongDescription(e)} />
              </div>
            </div>
            
            
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-4">Edition</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow" value={edition} onChange={(e) => setEdition(e)} />
              </div>
            </div>

            
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-4">Instructions</h5>
              <div className="col-12 mb-3">
                <ReactQuill theme="snow"  value={instructions} onChange={(e) => setInstructions(e)} />
              </div>
            </div>


            <div className="row">
              <h5 className="main_text_color mb-4">SEO info</h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input onChange={(e) => setMetaTitle(e.target.value)} value={metaTitle} type="text" className="form-control" />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea onChange={(e) => setMetaDescription(e.target.value)} value={metaDescription} name="META DESCRIPTION" className="form-control" rows="3"></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <FileUpload onFileChange={(val) => setMetaImage(val)} />
              </div>
              <div className="col-12">
                <CustomButton btntype="button" ClickEvent={handleSubmit} isBtn iconName="fa-solid fa-check" btnName="Save" />
              </div>
            </div>
          </DashboardBox>
        </div>
        <div className="col-12 col-md-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-3">Product Image Info</h5>
              <div className="row">
                <div className="col-12 mb-3">
                  <label>Main Image </label>
                  <FileUpload onFileChange={(val) => setMainImage(val)} />

                  <br />
                  {mainImage && mainImage && <img style={{ height: 100, width: 100 }} src={mainImage.includes('base64') ? mainImage : generateFilePath(mainImage)} alt="" />}

                </div>

                <div className="col-12 mb-3">
                  <label>Alt Image</label>
                  <input onChange={(e) => setMainImageAlt(e.target.value)} value={mainImageAlt} type="text" className="form-control" />
                </div>
                <div className="col-12 mb-3">
                  <label>Back Image </label>

                  <FileUpload onFileChange={(val) => setBackImage(val)} />
                  {backImage && backImage && <img style={{ height: 100, width: 100 }} src={backImage.includes('base64') ? backImage : generateFilePath(backImage)} alt="" />}

                </div>
                <div className="col-12 mb-3">
                  <label>Alt Back Image</label>
                  <input onChange={(e) => setBackImageAlt(e.target.value)} value={backImageAlt} type="text" className="form-control" />
                </div>
                {/* <div className="col-2 me-5">
                  <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                </div>
                <div className="col-2">
                  <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                </div> */}
              </div>
              <div className="col-12 mb-3">
                <label>
                  Multiple Product Image
                  <MultiFileUpload
                    onFileChange={(val) => handleFileSet(val, 0)} />
                </label>

                {imageArr &&
                  imageArr.length > 0 &&
                  imageArr.map((el, index) => {
                    return (
                      <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                        <div className="col-12">
                          <label>
                            Image {index + 1}     <button type="button" className="btn btn-sm btn-danger " onClick={() => handleImageObjRemove(index)}  >
                              <i className="fa fa-close"></i>
                            </button>
                          </label>

                          <div className="row">{el?.image && el.image && <img style={{ height: 200 }} src={el.image.includes('base64') ? el.image : generateFilePath(el.image)} alt="" />}</div>
                        </div>

                        <div className="col-12">
                          <label>
                            Product Image alt<span className="red">*</span>
                          </label>
                          <input onChange={(e) => handleproductImageAltEntry(e.target.value, index)} value={el?.imageAlt} type="text" className="form-control" />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-3">Product Video Link</h5>
              <div className="col-12 mb-3">
                <label>Video Link</label>
                <input onChange={(e) => setVideoLink(e.target.value)} type="text" className="form-control" />
              </div>
            </div>

            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-3">Pdf Specifications</h5>
              <div className="col-12 mb-3">
                <label>PDF SPECIFICATIONS</label>
                <FileUpload onFileChange={handlePdfFileSet} />
              </div>
            </div> */}
            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="main_text_color mb-3">Product Video Info</h5>
              <div className="col-12 mb-3">
                <label>VIDEO PROVIDER</label>
                <Select options={options} />
              </div>
              <div className="col-12 mb-3">
                <label>VIDEO LINK</label>
                <input type="url" className="form-control" />
              </div>
            </div> */}
            <div className="row">
              <h5 className="main_text_color mb-3">Others Info</h5>
              <div className="col-12 mb-3">
                <label>
                  STATUS<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setStatus("Publish")} checked={status == "Publish"} className="form-check-input pointer" type="radio" name="product-status" value="option1" id="product-publish" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-publish">
                      Publish
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setStatus("Pending")} checked={status == "Pending"} className="form-check-input pointer" type="radio" name="product-status" value="option2" id="product-pending" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-pending">
                      Pending
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </DashboardBox>
        </div>
      </div>
    </form>
  );
}

export default UpdateGeneralProduct;
